<template>
  <div>

    <b-form>

      <h5 class="mt-4 mb-8">{{ $t('PAGES.RENEWS.RENEW_MEMBERSHIPS') }}</h5>

      <Confirm
        ref="confirm-create-batch"
        :title="$t('MEMBER_RENEW.CONFIRM_RENEW')"
        :message="$t('MEMBER_RENEW.CONFIRM_CREATE_RENEWAL_BATCH')"
        :message2="$t('MEMBER_RENEW.COUNT_MEMBERS', { count: member_ids.length })"
        @confirm="confirmed_continue"
        @cancel="confirm_cancel"
      />

      <b-row>
        <b-col lg="12">
          <p>{{ $t('MEMBER_RENEW.MEMBERS_TO_RENEW_INFO') }}</p>
        </b-col>
      </b-row>

      <b-form-group
        label-size="sm"
        label-cols="4"
        content-cols="6"
        :label="$t('MEMBER_RENEW.RENEWAL_TYPE')">

        <b-form-select
          @change="on_status_change"
          v-model="form.renewal_type"
          :options="renew_type_options"></b-form-select>
        <b-form-text>{{ $t('MEMBER_RENEW.RENEWAL_TYPE_INFO') }}</b-form-text>
      </b-form-group>

      <b-row class="mb-4 mt-8">
        <b-col lg="12">
          <h5>{{ $t('PAGES.RENEWS.SELECT_SENDOUT_METHOD') }}</h5>
        </b-col>
      </b-row>

      <b-form-group
        label-size="sm"
        label-cols="4"
        content-cols="6"
        :label="$t('MEMBER_RENEW.EMAIL_OPTION')">

        <b-form-select
          v-model="form.email_option"
          :options="send_options"></b-form-select>

      </b-form-group>

      <!-- if email sendout, show options -->
      <div v-if="form.email_option === 'DO_SEND'">

        <b-form-group
          label-size="sm"
          label-cols="4"
          content-cols="6"
          :label="$t('MEMBER_RENEW.EMAIL_TEMPLATE')">

          <b-form-select
            v-model="form.email_template_id"
            :options="template_email_options"></b-form-select>

        </b-form-group>
      </div>

      <b-form-group
        label-size="sm"
        label-cols="4"
        content-cols="6"
        :label="$t('MEMBER_RENEW.SMS_OPTION')">

        <b-form-select
          v-model="form.sms_option"
          :options="send_options"></b-form-select>

      </b-form-group>

      <!-- if sms sendout, show options -->
      <div v-if="form.sms_option === 'DO_SEND'">

        <b-form-group
          label-size="sm"
          label-cols="4"
          content-cols="6"
          :label="$t('MEMBER_RENEW.SMS_TEMPLATE')">

          <b-form-select
            v-model="form.sms_template_id"
            :options="template_sms_options"></b-form-select>

        </b-form-group>

      </div>



  <!--
      <b-row>
      <b-col lg="12">
          <b-form-group id="period_id-group" label="Förnya till period" label-for="period_id">
          <b-form-select
              id="period_id"
              v-model="renew.period_id"
              :options="periods"
              text-field="name"
              value-field="id"
              disabled
          ></b-form-select>
          </b-form-group>
      </b-col>
      </b-row> -->

      <b-row>
        <b-col cols="12">
          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('MEMBER_RENEW.TO_PERIOD')">

            <b-form-select
              v-model="form.to_period_id"
              :options="period_options"></b-form-select>

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group
            label-size="sm"
            label-cols="4"
            content-cols="6"
            :label="$t('MEMBER_RENEW.COUNT_MEMBERS_TO_RENEW')">

            <b-form-input
              :disabled="true"
              v-model="member_ids.length" />

          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col class="ml-8">
          <ul>
            <li
              v-for="(error, idx) in errors"
              :key="idx"
            >
              {{ error.text }}
            </li>

          </ul>
        </b-col>

      </b-row>

      <RightSaveButton
        ref="save-button"
        :text="$t('MEMBER_RENEW.CREATE_RENEWS')"
        @clicked="save_data()"
      />

    </b-form>
  </div>

</template>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_memlist_checkbox.scss";

ul li {
  list-style: none;
  color: red;
  font-weight: bold;
}

.select2-container  {
  width: 100% !important;
}
.select2-container--default {
  width: 100% !important;
}
</style>

<script>
import 'vue-select/dist/vue-select.css';
import Select2 from 'v-select2-component';

import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import TipLabel from '@/view/components/TipLabel.vue';

import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';


export default {

  name: 'RenewBatchEditor',

  components: {
    Select2,
    Confirm,
    TipLabel,
    RightSaveButton
  },

  mixins: [ toasts ],

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods']),

  },
  mounted() {
    const loader = this.$loading.show();

    this.period_options = this.periods.map((item) => ({ text: item.name, value: item.id }));
    this.form.to_period_id = this.currentPeriodId;
    this.form.period_id = this.currentPeriodId;
    this.form.company_id = this.currentCompanyId;

    /*if (this.item) {
      this.form = { ...this.item };
    }
    else {
      this.reset_form();
    }*/

    this.get_templates();
    this.get_settings();

    loader && loader.hide();
  },
  data() {
    return {
      form: {
        period_id: null,
        renewal_type: 'RENEWAL',
        sms_option: 'DONT_SEND',
        email_option: 'DONT_SEND'
      },
      period_options: [],

      send_options: [
        { value: 'DO_SEND', text: this.$t('MEMBER_RENEW.DO_SEND') },
        { value: 'DONT_SEND', text: this.$t('MEMBER_RENEW.DONT_SEND') },
      ],

      status_options: [
        { value: 'SCHEDULED', text: this.$t('MEMBER_RENEW.STATUSES.SCHEDULED') },
        { value: 'ACTIVE', text: this.$t('MEMBER_RENEW.STATUSES.ACTIVE') },
        { value: 'STARTED_LINK', text: this.$t('MEMBER_RENEW.STATUSES.STARTED_LINK') },
        { value: 'INVOICED', text: this.$t('MEMBER_RENEW.STATUSES.INVOICED') },
        { value: 'TIME_OUT', text: this.$t('MEMBER_RENEW.STATUSES.TIME_OUT') },
        { value: 'AWAITING_PAYMENT', text: this.$t('MEMBER_RENEW.STATUSES.AWAITING_PAYMENT') },
        { value: 'DONE', text: this.$t('MEMBER_RENEW.STATUSES.DONE') },
        { value: 'DELETED', text: this.$t('MEMBER_RENEW.STATUSES.DELETED') },
      ],

      renew_type_options: [
        { value: 'RENEWAL', text: this.$t('MEMBER_RENEW.IS_RENEWAL') },
        { value: 'CONFIRM', text: this.$t('MEMBER_RENEW.IS_CONFIRM') },
      ],

      paying_options: [
        { value: true, text: this.$t('COMMON.PAID') },
        { value: false, text: this.$t('COMMON.NOT_PAID') }
      ],

      template_sms_options: [],
      template_email_options: []

    }
  },
  props: ['member_ids'],
  emits: ['created'],
  /*watch: {
    item: {
      deep: true,
      handler(val) {

        if (!this.item || !this.item.shop_item_id) {
          this.reset_form();
        }
        else {
          this.form = { ...this.item }
        }
      }
    },
  },*/

  methods: {

    async confirmed_continue() {
      await this.create_batch();

      this.$refs['save-button'].stop();
    },

    confirm_cancel() {
      this.$refs['save-button'].stop();
    },

    // when we have the intention to create the renew batch
    save_data() {
      this.$nextTick(()=>{

        this.$refs['confirm-create-batch'].show();
      });
    },

    async get_settings() {
      try {
        const res = await axios.get(`/company/admin?company_id=${this.currentCompanyId}`)

        if (res.status === 200 && res.data.renew) {
          this.settings = res.data.renew;
          this.form.email_template_id = this.settings.email_template_id;
          this.form.sms_template_id = this.settings.sms_template_id;
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_GET'));
        }

      }
      catch (err) {
        console.error('get_settings', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_GET'));
      }

    },

    async create_batch() {

      // when user has confirmed the creation of the renew batch
      // we get an object with options that contain all information we need

      /**
       * options: {
       *   from_period_id: number,
       *   to_period_id: number,
       *   sms_option: 'DO_SEND' | 'DONT_SEND',
       *   email_option: 'DO_SEND' | 'DONT_SEND',
       *   sms_template_id: null | number,
       *   email_template_id: null | number,
       *   scheduled_at: null | string, // when first sendout should be made
       *   invoice_option: 'DO_SEND' | 'DONT_SEND',
       *   invoice_template_id: null | number,
       *   is_confirm: boolean, // if its only a confirmation, no payment or full information(renew wizard)
       * }
       */

      const loader = this.$loading.show();

      try {
        const res = await axios.post(`/member_renew/job`, {
          renewal_type:       this.form.is_confirm ? 'CONFIRM' : 'RENEWAL',
          is_confirm:         this.form.is_confirm,
          member_ids:         this.member_ids,
          company_id:         this.currentCompanyId,
          to_period_id:       this.form.to_period_id,
          email_option:       this.form.email_option,
          sms_option:         this.form.sms_option,
          email_template_id:  this.form.email_template_id,
          sms_template_id:    this.form.sms_template_id,
          period_id:          this.currentPeriodId });

        if (res.status === 201) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.CREATED') + ': ' + res.data.num_records);

          this.$emit('created', res.data);
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_RENEW.UNABLE_CREATE_BATCH'));
        }

      }
      catch (err) {
        console.error('confirmed_renewal_batch_create error', err);

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('MEMBER_RENEW.UNABLE_CREATE_BATCH'));
      }

      loader && loader.hide();

      this.$refs['save-button'].stop();

    },

    async get_templates() {
      try {
        const res = await axios.get(`/template?company_id=${this.currentCompanyId}`);

        if (res.status !== 200) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
          return;
        }

        for (const t of res.data) {
          if (t.is_sms) {
            this.template_sms_options.push({ value: t.template_id, text: t.name });
          }
          else {
            this.template_email_options.push({ value: t.template_id, text: t.name });
          }

        }

      }
      catch (err) {
        console.error('get_templates', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
      }

    },

    reset_form() {

    }
  }
};
</script>
