<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <h4>{{ $t('ADVANCED_SEARCH.MEMBERSHIPS.HEADER') }}</h4>
      <!-- Add membership begin -->

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('ADVANCED_SEARCH.MEMBERSHIPS.COMPANY_NAME')">

                <b-form-select
                  @change="company_changed"
                  v-model="company_id"
                  :options="company_options"
                ></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('ADVANCED_SEARCH.MEMBERSHIPS.MEMBERSHIP_NAME')">

                <b-form-select
                  @change="shop_item_changed"
                  v-model="shop_item_id"
                  :options="shop_item_options"
                ></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>


        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>
            <div class="justify-content-end d-flex">
              <b-button class="mr-2 cancel" variant="danger" @click="$emit('cancel')" >{{$t('COMMON.CANCEL')}}</b-button>
              <b-button ref="save" class="save" variant="primary" type="submit">{{$t('COMMON.SELECT')}}</b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>


    </div>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';


export default {

  name: 'MembershipsSelectEditor',

  props: ['shop_items'],
  emits: ['selected', 'cancel'],
  mixins: [ toasts ],

  components: {

  },

  watch: {

  },

  computed: {
    ...mapGetters(['currentCompanyId','companies']),
    shop_item_options() {
      if (!this.shop_items) {
        return [];
      }

      return this.shop_items.filter(item => item.company_id === this.company_id).map(item => {
        return {
          value: item.shop_item_id,
          text: item.name
        };
      });
    },

    company_options() {
      if (!this.companies) {
        return [];
      }

      return this.companies.map(item => {
        return {
          value: item.company_id,
          text: item.name
        };
      });
    }

  },


  mounted() {

  },

  methods: {

    async shop_item_changed() {},

    async company_changed() {

    },


    async on_submit() {

      if (this.shop_item_id) {
        this.$emit('selected', this.shop_item_id);
      }
    },
  },


  data() {
    return {

      company_id: null,

      shop_item_id: null
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
