<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h5>&nbsp;</h5>

      <memlist-dropdown
        :options="dropdown_options"
        right_icon="true"
      />

    </div>

    <b-modal ref="success-job" hide-footer>
      <div class="row">
        <div class="col-12 text-center">
          <i class="fa fa-check-circle" style="font-size: 42px; color: #8dda8d;"></i>
        </div>
      </div>
      <div class="row" style="margin-top: 15px; border-top: 1px solid #ebedf2; padding-top: 20px;">
        <div class="col-12 text-center">
          <h4 class="event-name" style="text-align: center;">{{$t('PAGES.JOBS.CREATED')}}</h4>
          <br />
          <p>
            {{$t('EXPORT.MEMBER_LETTERS_CREATED')}}
          </p>
        </div>
      </div>
    </b-modal>

  </div>

  
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'AdvancedSearchSaveQueryButton',
  mixins: [ toasts ],
  components: {

  },
  props: ['column_names','search_options'],
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods']),
    dropdown_options() {
      return [
        { text: 'A', value: 'a' },
        { text: 'B', value: 'b' },
      ]
    }
  },
  data() {
    return {
      hover: false,
      show_dropdown: false
    };
  },
  async mounted() {
    // Close the dropdown if the user clicks outside of it

    
  },
  beforeDestroy() {
    // Remove the event listener when the component is destroyed
    document.removeEventListener('click', this.handle_click_outside);
  },
  watch: {
    
  },
  methods: {

    handle_click_outside() {
      if (!this.hover) {
        this.show_dropdown = false;

        document.removeEventListener('click', this.handle_click_outside);
      }
    },

    toggle_dropdown() {
      this.show_dropdown = true;

      setTimeout(() => {
        document.addEventListener('click', this.handle_click_outside);
      }, 100)
      
    },

    async get_member_ids_from_search_options() {
      try {
        const res = await axios.post(`/search/pagination/options/ids`, { search_options: this.search_options });

        if (res.status === 200) {
          return res.data;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.UNABLE_TO_SEARCH'))
      }

      return [];
    },
    
    async export_member_letter_labels_clicked() {
      if (this.search_options === null) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.NO_RECORDS_IN_SELECTION'));
        return;
      }

      const loader = this.$loading.show();

      try {
        const member_ids = await this.get_member_ids_from_search_options();

        if (member_ids.length === 0) {
          loader && loader.hide();
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.NO_RECORDS_IN_SELECTION'));
          return;
        }

        const res = await axios.post('/export/letter_labels/member/query', {
          search_options: this.search_options
        });

        if (res.status === 201) {
          loader && loader.hide();
          this.$refs['success-job'].show();

          //downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

          //this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.EXPORT_SUCCESS'));
        }
        else {
          loader && loader.hide();
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
        }
      }
      catch (err) {
        loader && loader.hide();
        console.error('export_member_letter_labels', err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
      }
    },
    async export_excel_clicked(e) {
      e.preventDefault();

      if (this.search_options === null) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ADVANCED_SEARCH.NO_RECORDS_IN_SELECTION'));
        return;
      }

      try {

        const res = await axios.post('/search/pagination/options/xlsx', {
          search_options: this.search_options,
          columns: this.column_names,
          company_id: this.currentCompanyId
        });

        if (res.status === 201) {
          downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);

          this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.EXPORT_SUCCESS'));
          return;
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
      }
    }
  }
};
</script>

<style scoped>

  .memlist-button {
    border-radius: 4px;
    border: 1px solid #3699FF; /* Added border color */
    padding: 8px 12px;
    cursor: pointer; /* Moved cursor pointer here */
    color: #3699FF;
    background-color: white;
    transition: background-color 0.2s; /* Smooth transition */
  }

  .memlist-button:hover {
    background-color: #dfefff; /* Slight contrast on hover */
  }

  .memlist-button.memlist-secondary:hover {
    background-color: #dfefff;
  }


  /* Container for the dropdown */
  .dropdown {
      position: relative;
      display: inline-block;
  }

  /* The popup list (hidden by default) */
  .dropdown-content {
      
      position: absolute;
      background-color: #fff;
      min-width: 160px;
      margin-top: 5px;
      border: 1px solid #ccc;
      z-index: 1;
      padding: 0;
      list-style-type: none;
  }

  /* List items */
  .dropdown-content li {
      padding: 10px;
  }

  /* Highlight and pointer on hover */
  .dropdown-content li:hover {
      background-color: #f1f1f1;
      cursor: pointer;
  }

  /* Show the list when the dropdown is active */
  .dropdown.active .dropdown-content {
      display: block;
  }
</style>