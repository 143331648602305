<template>
  <div class="px-5 mt-8" id="page-basic0">
    <b-row>

      <b-col lg="12">
        <b-card title="" class="mb-2 mx-auto mt-8" hide-footer>
          <div>
            <b-form class="mt-8" @submit.prevent="saveData">

              <b-row>
                <b-col cols="3">
                  <div class="d-flex align-items-center mb-6">
                    <label
                      class="checkbox checkbox-lg checkbox-outline checkbox-success"
                    >
                      <input
                        type="checkbox"
                        name=""
                        v-model="form.auto_renew"
                        @click="form.auto_renew = !form.auto_renew"
                      />
                      <span></span>
                    </label>
                    <span class="ml-3 cursor-pointer"
                      >{{$t('PAGES.RENEWS.ACTIVATE_AUTORENEW')}}</span
                    >
                  </div>
                </b-col>
                <b-col cols="3">
                  <b-form-group id="input-group-last_run" :label="$t('PAGES.RENEWS.LAST_RUN')" label-for="input-last_run">
                    <b-form-input id="input-last_run" v-model="form.last_run" type="text"></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-button style="margin-top: 26px;" variant="primary" :disabled="running_autorenew"  @click.prevent="force_run_autorenew_clicked">
                    <b-spinner v-if="running_autorenew" small type="grow"></b-spinner>
                    {{ $t('MEMBER_RENEW.FORCE_RUN_AUTORENEW') }}
                  </b-button>
                </b-col>
              </b-row>



              <div class="d-flex align-items-center mb-6">
                <label
                  class="checkbox checkbox-lg checkbox-outline checkbox-success"
                >
                  <input
                    type="checkbox"
                    name=""
                    v-model="form.send_email"
                    @click="form.send_email = !form.send_email"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer"
                  >{{$t('COMMON.SEND_EMAIL')}}</span
                >
              </div>

              <div class="d-flex align-items-center mb-6">
                <label
                  class="checkbox checkbox-lg checkbox-outline checkbox-success"
                >
                  <input
                    type="checkbox"
                    name=""
                    v-model="form.email_to_parent"
                    @click="form.email_to_parent = !form.email_to_parent"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer"
                  >{{$t('PAGES.RENEWS.SEND_TO_PARENT')}}</span
                >
              </div>


              <b-form-group id="input-group-email_template_id"
                :label="$t('PAGES.RENEWS.RENEW_TEMPLATE')" label-for="input-email_template_id">
                <b-form-select

                  id="type"
                  v-model="form.email_template_id"
                  :options="templateEmailOptions"
                ></b-form-select>
              </b-form-group>

              <div class="d-flex align-items-center mb-6">
                <label
                  class="checkbox checkbox-lg checkbox-outline checkbox-success"
                >
                  <input
                    type="checkbox"
                    name=""
                    v-model="form.send_sms"
                    @click="form.send_sms = !form.send_sms"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer"
                  >{{$t('COMMON.SEND_SMS')}}</span
                >
              </div>

              <b-form-group id="input-group-sms_template_id"
                :label="$t('PAGES.RENEWS.RENEW_TEMPLATE_SMS')" label-for="input-sms_template_id">
                <b-form-select
                  id="type"
                  v-model="form.sms_template_id"
                  :options="templateSMSOptions"
                ></b-form-select>
              </b-form-group>

              <b-form-group
                id="input-group-send_at_time"
                :label="$t('PAGES.RENEWS.SEND_AT_TIME')"
                label-for="input-send_at_time"
              >
              <b-form-select
                id="send_at_time"
                v-model="form.send_at_time"
                :options="timeOptions"
                ></b-form-select>
              </b-form-group>

              <b-form-group id="input-group-num_remind" :label="$t('PAGES.RENEWS.NUM_REMIND')" label-for="input-num_remind">
                <b-form-input id="input-num_remind" v-model="form.num_remind" type="text"></b-form-input>
              </b-form-group>

              <b-form-group id="input-group-num_remind" :label="$t('PAGES.RENEWS.DBR')" label-for="input-num_remind">
                <b-form-input id="input-num_remind" v-model="form.dbr" type="text"></b-form-input>
              </b-form-group>



              <b-card>
                <h4>{{$t('PAGES.RENEWS.CREATE_RENEW_AT')}}</h4>
                <b-row>
                  <b-col lg="12">
                    <div class="radio-list mt-4">

                    <label class="radio radio-outline radio-success mb-4">
                      <input type="radio" v-model="beforeOrAfter" value="before" name="type" />
                      <span></span>
                      {{$t('PAGES.RENEWS.BEFORE_LAST_DAY')}}
                    </label>

                    <label class="radio radio-outline radio-success mb-4">
                      <input type="radio" v-model="beforeOrAfter" value="after" name="type" />
                      <span></span>
                      {{$t('PAGES.RENEWS.AFTER_LAST_DAY')}}
                    </label>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12">
                    <b-form-group id="input-group-dbr"
                      :label="$t('PAGES.RENEWS.NUM_DAYS')" label-for="input-dbr">
                     <b-form-input id="input-dbr" v-model="absNumDaysBefore" type="text"></b-form-input>
                     </b-form-group>
                  </b-col>
                </b-row>
              </b-card>



              <b-button type="submit" variant="primary">{{$t('COMMON.SAVE')}}</b-button>
            </b-form>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'renew-settings',
  components: {},
  mixins: [ toasts ],
  props: [],
  computed: {
    ...mapGetters(['currentPeriodId','currentCompanyId']),
    running_autorenew() {
      return this.form.last_run === '0001-01-01';
    }
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      interval_id: null,
      form: {
        num_days_before: 0,
        email_template_id: null,
        sms_template_id: null,
        send_email: false,
        send_sms: false,
        auto_renew: false,
        last_run: '0001-01-01',
        dbr: 0,
        email_to_parent: false
      },
      absNumDaysBefore: 0,
      beforeOrAfter: 'before',
      templateOptions: [],
      templateSMSOptions: [],
      templateEmailOptions: [],
      timeOptions: [
        { value: '00:00', text: '00:00' },
        { value: '01:00', text: '01:00' },
        { value: '02:00', text: '02:00' },
        { value: '03:00', text: '03:00' },
        { value: '04:00', text: '04:00' },
        { value: '05:00', text: '05:00' },
        { value: '06:00', text: '06:00' },
        { value: '07:00', text: '07:00' },
        { value: '08:00', text: '08:00' },
        { value: '09:00', text: '09:00' },
        { value: '10:00', text: '10:00' },
        { value: '11:00', text: '11:00' },
        { value: '12:00', text: '12:00' },
        { value: '13:00', text: '13:00' },
        { value: '14:00', text: '14:00' },
        { value: '15:00', text: '15:00' },
        { value: '16:00', text: '16:00' },
        { value: '17:00', text: '17:00' },
        { value: '18:00', text: '18:00' },
        { value: '19:00', text: '19:00' },
        { value: '20:00', text: '20:00' },
        { value: '21:00', text: '21:00' },
        { value: '22:00', text: '22:00' },
        { value: '23:00', text: '23:00' },
      ]
    };
  },
  watch: {
    beforeOrAfter(newVal, oldVal) {
      this.form.num_days_before = this.absNumDaysBefore * (newVal === 'before' ? -1 : 1);
    },
    absNumDaysBefore(newVal, oldVal) {
      if (newVal) {
        this.form.num_days_before = newVal * (this.beforeOrAfter === 'before' ? -1 : 1);
      }
    }
  },
  methods: {

    async poll_autorenew() {
      try {
        const res = await axios.get(`/company/admin?company_id=${this.currentCompanyId}`);

        if (res.status === 200) {
          if (res.data.renew.last_run !== '0001-01-01') {
            clearInterval(this.interval_id);
            this.interval_id = null;
            this.form.last_run = res.data.renew.last_run;
          }

          return;
        }
      }
      catch (err) {
        console.error('poll_autorenew', err);
      }

      clearInterval(this.interval_id);
    },


    async force_run_autorenew_clicked() {
      try {

        this.form.last_run = '0001-01-01';

        const res = await axios.put(`/company/${this.currentCompanyId}`, { renew: this.form });

        if (res.status === 200) {
          this.form.last_run = '0001-01-01';
          this.interval_id = setInterval(()=>{ this.poll_autorenew(); },1000);
          return;
        }

      }
      catch (err) {

      }

      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.RENEWS.UNABLE_RUN_AUTORENEW'));
    },
    saveData() {
      axios
        .put(`/company/${this.currentCompanyId}`, { renew: this.form })
        .then(res => {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SETTINGS.UPDATED'));
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_UPDATE'));
        });

    },
    loadData() {
      axios
        .get(`/company/admin?company_id=${this.currentCompanyId}`)
        .then(res => {
          if (res.data.renew) {
            this.form = res.data.renew;
          }

          if (this.form.num_days_before < 0) {
            this.beforeOrAfter = 'before';
          }
          else {
            this.beforeOrAfter = 'after';
          }
          this.absNumDaysBefore = Math.abs(this.form.num_days_before);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_GET'));
          console.error(err);
        });

      axios
        .get(`/template?company_id=${this.currentCompanyId}`)
        .then(res => {
          this.templateOptions = [];

          for (const t of res.data) {
            if (t.is_sms) {
              this.templateSMSOptions.push({ value: t.template_id, text: t.name });
            }
            else {
              this.templateEmailOptions.push({ value: t.template_id, text: t.name });
            }
            //this.templateOptions.push({ value: t.template_id, text: t.name });
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('TEMPLATE.UNABLE_LIST'));
        });
    }
  }
};
</script>
