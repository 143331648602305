<template>

  <b-modal ref="modal" v-model="showExportOptionsModal" hide-footer>
    <b-row v-if="showError">
        <b-col lg="12">
            <b-alert variant="danger" show>{{ errorText }}</b-alert>
        </b-col>
    </b-row>

    <b-row>
        <b-col lg="6">
          <memlist-date-picker
            v-model="created_from"
            class="mb-8"
            :placeholder="$t('COMMON.CREATED_FROM')"
          >
          </memlist-date-picker>
        </b-col>
        <b-col lg="6">
          <memlist-date-picker
            v-model="created_to"
            class="mb-8"
            :placeholder="$t('COMMON.CREATED_TO')"
          >
          </memlist-date-picker>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
          <b-form-group id="input-group-status" label="Status" label-for="input-status">
            <b-form-select
              id="status"
              v-model="status"
              :options="statusOptions"
            ></b-form-select>
          </b-form-group>

        </b-col>
    </b-row>

    <b-row class="mt-6">
        <b-col lg="6">
        <b-button variant="outline-success" :disabled="inputValid" @click="save">
          {{$t('COMMON.CONTINUE')}}
        </b-button>
        </b-col>
        <b-col lg="6">
        <b-button variant="outline-primary" @click="cancel">
          {{$t('COMMON.CANCEL')}}
        </b-button>
        </b-col>
    </b-row>
  </b-modal>

</template>

<script>
import axios from 'axios';

import Confirm from '@/view/components/Confirm.vue';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'ExportOptionsModal',
  components: {
    Confirm
  },
  mixins: [ toasts ],
  data() {
    return {
      statusOptions: [
          { value: 'ALL', text: this.$t('COMMON.ALL') },
          { value: 'ACTIVE', text: this.$t('COMMON.ACTIVE') },
          { value: 'DONE', text: this.$t('COMMON.DONE') },
      ],
      created_from: null,
      created_to: null,
      status: 'ALL',
      showError: false,
      errorText: this.$t('COMMON.AN_ERROR_OCCURED'),
      showExportOptionsModal: false,
    };
  },
  props: {

  },
  emits: ['cancel'],
  watch: {
  },
  computed: {
    inputValid() {
      return !(this.created_from !== null && this.created_to !== null);
    }
  },
  async mounted() {

  },
  methods: {
    show() {
      this.showExportOptionsModal = true;
    },
    close() {
      this.showExportOptionsModal = false;
    },
    cancel() {
      this.$refs['modal'].hide();
      this.$emit('cancel');
    },
    save() {
      const body = {
        status: this.status === 'ALL' ? undefined : this.status,
        created_from: this.created_from,
        created_to: this.created_to
      }

      this.postXlsx(body);
    },

    postXlsx(body) {
      axios
        .post('/member_renew/xlsx', body)
        .then(res => {
          if (res.status === 201) {
            downloadWithAxios(get_base_url() + `/dlfile/${res.data.linkstr}`, res.data.name);
            this.close();
          }
          else {
            console.error(res.data);
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_EXPORT'));
        });
    },

  }
};
</script>
<style scoped>
</style>

