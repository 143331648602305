<template>
  <div id="page-member-manager">
    <h5 class="m-4">{{$t('AUTH.MENU.RENEWS')}}</h5>

    <v-tabs background-color="white" color="accent-4" left>
      <v-tab>{{$t('PAGES.RENEWS.RENEWALS')}}</v-tab>
      <v-tab>{{$t('PAGES.RENEWS.MANUAL')}}</v-tab>
      <v-tab>{{$t('COMMON.SETTINGS')}}</v-tab>
      
      <v-tab-item>
        <RenewTable
          
        />
      </v-tab-item>

      <v-tab-item>
        <div class="mt-8 container">

          <RenewBatchModal
            ref="renew-batch-modal"
            class="renew-container"
            
            :member_ids="member_ids"
            @created="renew_batch_created"
          />

          <AdvancedSearchMemberEditor
            class="px-2"
            ref="advanced-search-member-editor"
            :expand="true"
            :show_save_search="false"
            source="RENEW"
            @on_search_result="on_search_result"
            @on_search_count_result="on_search_count_result"
          />

          <div class="d-sm-flex justify-content-between align-items-center">
            <h5>&nbsp;</h5>

            <b-button class="mb-4" variant="primary" :disabled="member_ids.length === 0" @click="open_renew_batch_modal">
              {{ $t('MEMBER_RENEW.CREATE_RENEW_BATCH', { count: this.member_ids.length }) }}
            </b-button>

          </div>
        
          <AdvancedSearchTable 
            :count="count"
            :members="members"
            :search_options="search_options"
            :fixed_columns="fixed_columns"
            @per_page_changed="per_page_changed"
          />

        </div>
        
      </v-tab-item>

      <v-tab-item>
        <RenewSettings />
      </v-tab-item>
    </v-tabs>
    
  </div>
</template>

<style lang="scss" scoped>
#page-advanced-search {
  .create-member {
    padding: 25px;
    display: flex;
    flex: 1;
    span {
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

import RenewSettings from '@/view/pages/ml/renew/RenewSettings.vue';
import RenewTable from '@/view/pages/ml/renew/RenewTable.vue';
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import AdvancedSearchMemberEditor from '@/view/pages/ml/advanced_search/AdvancedSearchMemberEditor.vue';
import AdvancedSearchTable from '@/view/pages/ml/advanced_search/AdvancedSearchTable.vue';
import RenewBatchModal from './renew/RenewBatchModal.vue';


export default {
  name: 'RenewPage',
  components: {
    RenewTable,
    RenewSettings,
    AdvancedSearchMemberEditor,
    AdvancedSearchTable,
    RenewBatchModal
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'periods'])
  },
  mixins: [ toasts ],
  watch: {
    periods() {
      this.setPeriods();
    }
  },
  data() {
    return {
      fixed_columns: ['id','public_id','firstname','lastname','email','phone','zipcode','post'],
      count: 0,
      members: [],
      member_ids: [],
      search_options: null,
      from_period: null,
      to_period: null,
      showNew: false,
      selectedSearch: null,
      
      searchHistory: [{ value: null, text: this.$t('PAGES.SEARCH.SELECT_SEARCH') }],
      
    };
  },
  async mounted() {
    this.setPeriods();
  },
  methods: {

    open_renew_batch_modal() {
      this.$refs['renew-batch-modal'].show();
    },

    renew_batch_created(item) {
      console.log('renew batch has been created', item);

      this.$refs['renew-batch-modal'].hide();
    },

    per_page_changed(per_page, page) {
      this.$refs['advanced-search-member-editor'].change_per_page(per_page, page);
    },

    async on_search_result(result, search_options) {
      this.search_options = search_options;

      // result is an array of members currently shown in the table
      this.members = result;

      // store away the array of member ids so we can perform a renew batch for them
      this.member_ids = await this.get_member_ids_from_search_options(search_options);
    },

    on_search_count_result(count) {
      this.count = count;
    },

    async get_member_ids_from_search_options(search_options) {
      try {
        const res = await axios.post(`/search/pagination/options/ids`, { search_options: search_options });

        if (res.status !== 200) {
          console.error('get_member_ids_from_search_options, unable to get ids', res.status);
          return [];
        }

        return res.data;
      }
      catch (err) {
        console.error('get_member_ids_from_search_options', err);
      }

      return [];
    },
    
    setPeriods() {
      this.from_period = this.periods.find(item => item.id === this.currentPeriodId);
      this.to_period = this.periods.find(item => item.id === this.from_period.next_period_id);
    },
    
  }
};
</script>
