<template>
  <div>

    <b-modal
      ref="select-modal"
      hide-footer
    >
      <EventsSelectEditor
        :events="events"
        @selected="event_selected"
        @cancel="()=>{this.$refs['select-modal'].hide();}"
      />
    </b-modal>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100 mb-4">
      <a
        href="#"
        class="btn btn-primary font-weight-bolder font-size-sm mr-2 my-2"
        @click.prevent="open_select_editor"
        ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('ADVANCED_SEARCH.EVENTS.ADD_EVENT')}}</a
      >

    </div>

    <!-- Table -->
    <div class="table-container">
      <b-table
        id="select-table"
        class="table-striped"
        :fields="fields"
        :items="selected_rows"
        head-variant="light"
        ref="select-table"
      >
      <template #cell(action)="data">
        <div class="text-right">
          <a
            class="btn btn-icon btn-light btn-sm mx-2"
            @click.prevent="delete_row_clicked(data.item)"
          >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
            </span>
          </a>
        </div>
      </template>
      </b-table>

      <p class="ml-4" v-if="selected_rows.length === 0">{{$t('ADVANCED_SEARCH.EVENTS.NONE_SELECTED')}}</p>

    </div>

  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';
import EventsSelectEditor from './EventsSelectEditor.vue';

import { mapGetters } from 'vuex';

export default {

  name: 'EventsSelectTable',

  emits: ['select'],

  props: ['selected', 'search_options'],

  mixins: [ toasts ],

  components: {
    EventsSelectEditor
  },

  watch: {
    search_options: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_search_options = { ...this.search_options };
      }
    },
    currentCompanyId() {
      this.load_items();
    }
  },

  computed: {
    ...mapGetters(['currentCompanyId']),

    is_mobile() {
      return is_mobile();
    },

    selected_rows() {
      const rows = [];

      for (const event_id of this.local_search_options.events.event_ids) {
        const event = this.events.find(item => item.event_id === event_id);
        rows.push(event);
      }

      return rows;
    }
  },

  methods: {

    open_select_editor() {
      this.$refs['select-modal'].show();
    },

    event_selected(event_id) {

      this.$refs['select-modal'].hide();

      // check if it already exists

      const found = this.local_search_options.events.event_ids.find(item => event_id === item);

      if (found) {
        return;
      }

      this.local_search_options.events.event_ids.push(event_id);
    },

    async load_items() {
      try {
        const response = await axios.get(`/events/list?company_id=${this.currentCompanyId}`);
        this.events = response.data;
      }
      catch (err) {
        console.error('error loading events', err);
      }
    },

    async edit_row_clicked(item) {

      this.$nextTick(()=>{
        this.$emit('select', item);
      });
    },

    async delete_row_clicked(item) {

      this.local_search_options.events.event_ids = this.local_search_options.events.event_ids.filter((event_id) => event_id !== item.event_id);

      this.local_search_options.events.event_ids = [...this.local_search_options.events.event_ids];
    },

  },

  mounted() {

    if (this.search_options) {
      this.local_search_options = { ...this.search_options };
    }

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }

    this.load_items();
  },

  data() {
    return {

      local_search_options: {
        events: {
          event_ids: [],
          include: 'ANY'
        }
      },

      events: [],

      fields: [
        {
          key: 'name',
          label: this.$t('ADVANCED_SEARCH.EVENTS.NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

        {
          key: 'company',
          label: this.$t('ADVANCED_SEARCH.EVENTS.COMPANY_NAME'),
          sortable: false,
          mobile: true,
          tdClass: 'td-short2',
          thClass: 'td-short2',
        },
        {
          key: 'action',
          label: '',
          tdClass: 'td-actions',
          thClass: 'td-actions',
          sortable: false
        }
      ],

      show_form: true,

    };
  }
};

</script>


<style lang="scss" scoped>
  @import "@/assets/sass/components/forms/_memlist_table.scss";
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
