<template>
  <div class="no-padding">
    <b-modal
      ref="select-educations-modal"
    >
      
      <Multiselect
        class="ml-2 multiselect-blue"
        v-model="local_search_options.educations.education_ids"
        ref="educations_multiselect"
        mode="tags"
        :placeholder="$t('ADVANCED_SEARCH.EDUCATIONS.SEARCH_EDUCATION')"
        :close-on-select="false"
        :filter-results="false"
        :min-chars="0"
        :resolve-on-load="false"
        :delay="0"
        :searchable="true"
        :showNoOptions="false"
        :noOptionsText="$t('ADVANCED_SEARCH.NO_RESULTS')"
        :noResultsText="$t('ADVANCED_SEARCH.NO_RESULTS')"
        :options="async function(query) {
          return await fetch_education_options(query);
        }"
        @change="changed"
      />

    </b-modal>

    <b-button @click="open_modal" block variant="outline-primary">{{ $t('ADVANCED_SEARCH.EDUCATIONS.SELECT_EDUCATION', { count: local_search_options.educations.education_ids.length }) }}</b-button>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'

import { mapGetters } from 'vuex';


export default {

  name: 'EducationsSelectButton',

  props: ['search_options'],

  mixins: [ toasts ],

  components: {
    Multiselect
  },

  watch: {
    search_options: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_search_options = { ...this.search_options };
      }
    },
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
  },

  mounted() {
    if (this.search_options) {
      this.local_search_options = { ...this.search_options };
    }
    
  },

  methods: {

    open_modal() {
      this.$refs['select-educations-modal'].show();
    },
    
    async fetch_education_options(query) {
      const res = await axios.post(`/education/pagination`, { options: { text: query }});

      if (res.status === 200) {
        return res.data.map(item => ({ label: item.name, value: item.id }));
      }

      return [];
    },

    changed() {
      this.selected(this.local_search_options);
    },

    selected(search_options) {
      this.$emit('selected', search_options);
    }
  },

  data() {
    return {
      local_search_options: {
        educations: {
          education_ids: [],
          include: 'ANY'
        }
      },
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
