export function format_sek(str, currency = '') {
    if (str === null || str === undefined) {
        return '0,00 ' + currency;
    }

    const sstr = str + '';

    let numerals = '';
    let decimals = '';

    let do_decimals = false;

    for (let i = 0; i < sstr.length; ++i) {
        const c = sstr.substr(i, 1);

        if ('0123456789'.indexOf(c) !== -1) {
            if (do_decimals) {
                decimals += c;
            }
            else {
                numerals += c;
            }
        }

        if ('.,'.indexOf(c) !== -1) {
            do_decimals = true;
        }
    }

    let format_str = '';

    if (decimals.length === 0) {
        decimals = '00';
    }
    else if (decimals.length === 1) {
        decimals += '0';
    }
    else if (decimals.length > 2) {
        decimals = decimals.substr(0,2);
    }

    if (numerals.length > 3) {
        format_str = numerals.substr(0, numerals.length - 3);
        format_str += ' ' + numerals.substr(numerals.length - 3);
        format_str += ',' + decimals;
    }
    else {
        format_str = numerals + ',' + decimals;
    }
    
    return format_str + ' ' + currency;
}