import { render, staticRenderFns } from "./RenewBatchEditor.vue?vue&type=template&id=0720e482&scoped=true"
import script from "./RenewBatchEditor.vue?vue&type=script&lang=js"
export * from "./RenewBatchEditor.vue?vue&type=script&lang=js"
import style0 from "./RenewBatchEditor.vue?vue&type=style&index=0&id=0720e482&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0720e482",
  null
  
)

export default component.exports