<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <h4>{{ $t('ADVANCED_SEARCH.EVENTS.HEADER') }}</h4>

      <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label-size="sm"
              label-cols="4"
              content-cols="6"
              :label="$t('ADVANCED_SEARCH.EVENTS.NAME')">

                <b-form-select
                  v-model="event_id"
                  :options="event_options"
                ></b-form-select>

            </b-form-group>
          </b-col>
        </b-row>

        <p style="margin-top: 42px;"></p>

        <b-row>
          <b-col>
            <div class="justify-content-end d-flex">
              <b-button class="mr-2 cancel" variant="danger" @click="$emit('cancel')" >{{$t('COMMON.CANCEL')}}</b-button>
              <b-button ref="save" class="save" variant="primary" type="submit">{{$t('COMMON.SELECT')}}</b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>

    </div>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';


export default {

  name: 'EventsSelectEditor',

  props: ['events'],
  emits: ['selected', 'cancel'],
  mixins: [ toasts ],

  components: {

  },

  watch: {

  },

  computed: {
    ...mapGetters(['currentCompanyId']),
    event_options() {
      return this.events.map((item) => ({ text: item.name, value: item.event_id }));
    },

  },


  mounted() {

  },

  methods: {


    async on_submit() {
      this.$emit('selected', this.event_id);
    },
  },


  data() {
    return {

      event_id: null,

    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
