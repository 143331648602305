<template>
  <div class="no-padding">
    <b-modal
      ref="select-attributes-modal"
    >
      <AttributesSelectTable
        :search_options="local_search_options"
      />
    </b-modal>

    <b-button @click="open_modal" block variant="outline-primary">{{ $t('ADVANCED_SEARCH.ATTRIBUTES.SELECT_ATTRIBUTE', { count: local_search_options.properties.prop_ids.length }) }}</b-button>
  </div>
</template>


<script>
import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { mapGetters } from 'vuex';
import AttributesSelectTable from './AttributesSelectTable.vue';


export default {

  name: 'AttributesSelectButton',

  props: ['search_options'],

  mixins: [ toasts ],

  components: {
    AttributesSelectTable
  },

  watch: {
    search_options: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_search_options = { ...this.search_options };
      }
    },
  },

  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
  },

  mounted() {
    if (this.search_options) {
      this.local_search_options = { ...this.search_options };
    }
  },

  methods: {

    open_modal() {
      this.$refs['select-attributes-modal'].show();
    }
  },

  data() {
    return {
      local_search_options: {
        properties: {
          prop_ids: []
        }
      },
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
