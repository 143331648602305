<template>

  <b-modal ref="modal" :title="$t('INVOICE.GENERATION')" v-model="show_invoice_options_modal" hide-footer>

    <b-card class="mt-4">
      <b-form-group :label="$t('COMMON.NUM_ROWS')" label-for="text">
        <b-form-input autocomplete="off"
          id="count"
          v-model="count"
          :placeholder="$t('COMMON.NUM_ROWS')"
          :disabled="true"
        />
      </b-form-group>

      <b-form-group :label="$t('INVOICE.DUE_DATE')" label-for="due_date-input">
        <memlist-date-picker v-model="due_date"></memlist-date-picker>
      </b-form-group>

    </b-card>

    <b-row v-if="show_error">
      <b-col lg="12">
        <b-alert variant="danger" show>{{ error_text }}</b-alert>
      </b-col>
    </b-row>

    <b-row class="mt-6">
        <b-col lg="6">
        <b-button variant="outline-success" @click="save">
          {{$t('COMMON.CONTINUE')}}
        </b-button>
        </b-col>
        <b-col lg="6">
        <b-button variant="outline-primary" @click="cancel">
          {{$t('COMMON.CANCEL')}}
        </b-button>
        </b-col>
    </b-row>
  </b-modal>

</template>
<style lang="scss" scoped>
</style>
<script>
import Confirm from '@/view/components/Confirm.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'InvoiceCreateOptionsModal',
  components: {
    Confirm,
  },
  data() {
    return {
      due_date: null,
      create_option: 'FILTER',
      show_error: false,
      error_text: this.$t('COMMON.AN_ERROR_OCCURED'),
      show_invoice_options_modal: false,
      payment_status: 'WAIT'
    };
  },
  props: ['count'],
  emits: ['on_confirmed_generate_invoices', 'onCancelled'],
  mixins: [ toasts ],
  watch: {
  },
  computed: {
    emailOptionText() {
      switch (this.email_option) {
        case 'EXISTS': return this.$t('INVOICE.HAS_EMAIL');
        case 'NOT_EXISTS': return this.$t('INVOICE.MISSING_EMAIL');
      }

      return this.$t('COMMON.ALL');
    },

    createdFromText() {
      return this.created_from ? this.created_from : this.$t('COMMON.ALL');
    },

    createdToText() {
      return this.created_to ? this.created_to : this.$t('COMMON.ALL');
    },

    statusText() {

      const text = {
        SCHEDULED: this.$t('PAGES.RENEWS.SCHEDULED'),
        ACTIVE: this.$t('PAGES.RENEWS.ACTIVE'),
        STARTED_LINK: this.$t('PAGES.RENEWS.STARTED_LINK'),
        INVOICED: this.$t('PAGES.RENEWS.INVOICED'),
        DELETED: this.$t('PAGES.RENEWS.DELETED'),
        DONE: this.$t('PAGES.RENEWS.DONE'),
        AWAITING_PAYMENT: this.$t('PAGES.RENEWS.AWAITING_PAYMENT'),
        TIME_OUT: this.$t('PAGES.RENEWS.TIME_OUT')
      }[this.status];

      if (text === null || text === undefined) {
        return this.$t('COMMON.ALL');
      }

      return text;
    },
    paymentStatusText() {
      switch (this.payment_status) {
        case 'LATE': return this.$t('INVOICE.LATE');
        case 'PAID': return this.$t('INVOICE.PAID');
        case 'WAIT': return this.$t('INVOICE.WAIT');
      }

      return this.$t('COMMON.ALL');
    }
  },
  async mounted() {

  },
  methods: {
    show() {
      this.show_invoice_options_modal = true;
    },
    close() {
      this.show_invoice_options_modal = false;
    },
    cancel() {
      this.$refs['modal'].hide();
      this.$emit('onCancelled');
    },
    save() {
      this.$emit('on_confirmed_generate_invoices', this.due_date)
    },

  }
};
</script>
<style scoped>
</style>

